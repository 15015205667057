import styled from 'styled-components';

const CTA = styled.a`
    background: white;
    text-transform: none;
    font-size: 15px;
    font-weight: 900;
    text-transform: uppercase;
    padding: 11px 23px;
    border-radius: ${props => props.borderRadius};
    color: #10526E;
    cursor: pointer;
    border: none;
    text-decoration: none;
    transition: .3s;
    text-align: center;
    width: 100%;
    

    &:hover {
        text-decoration: none;
        background: #333132;
        transition: .3s;
        color: white;
    }
`;

CTA.defaultProps = {
    borderRadius: '12px'
};

export default CTA;
