import React from 'react';
import styled from 'styled-components';
import Cta from '../../../atoms/CTA-Dropshadow/index';
import formatCurrency from '../../../../helpers/formatCurrency';


const Container = styled.div`
    width: 1200px;
    margin: 0 auto 100px auto;

    @media (max-width: 1250px) {
        width: 100%;
        padding: 0 30px;
    }
`;

const Title = styled.h2`
    color: #10526E;
    font-size: 26px;
    margin-bottom: 0;
    font-weight: 900;
`;

const SubTitle = styled.p`
    color: #10526E;
    font-size: 17px;
    margin-bottom: 50px;

    @media (max-width: 600px) {
        margin: 20px 0 30px 0;
    }
`;

const BottomText = styled.p`
    color: #10526E;
    font-size: 17px;
    margin-top: 100px;

    a {
        color: #0CA14A;
        text-decoration: none;
    }

    @media (max-width: 1000px) {
        margin-top: 60px;
    }
    @media (max-width: 600px) {
        margin-top: 30px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-evenly;

    @media (max-width: 980px) {
        width: 100%;
        margin: 0;
    }
    @media (max-width: 769px) {
        flex-wrap: wrap;
    }
    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

const Offer = styled.h4`
    text-transform: uppercase;
    font-size: 24px;
    color: white;
    text-align: center;
    font-weight: 900;
    margin: 0 auto;

    @media (max-width: 600px) {
        font-size: 20px;
    }
`;

const OfferNumber = styled.p`
    color: #10526E;
    background-color: white;
    padding: 9px 20px;
    border-radius: 50%;
    margin: 0 auto;
    font-size: 20px;
    font-weight: 900;
`;

const OfferCard = styled.div`
    border-radius: 20px;
    padding: 30px 45px 5px 45px;
    height: 770px;
    box-shadow: 0px 10px 20px #00000029;
    background-color: white;
    background-color: #363636;
    display: flex;
    flex-direction: column;
    width: 37%;
    position: relative;
    background-color: ${({ backgroundColor }) => backgroundColor};
    margin: 0 10px;

    @media (max-width: 1000px) {
        margin: 0 8px;
        height: 780px;
    }

    @media (max-width: 850px) {
        padding: 30px 20px 5px 20px;
    }
    @media (max-width: 769px) {
        width: 47%;
        margin: 15px auto;
    }
    @media (max-width: 600px) {
        width: 100%;
        margin-bottom: 20px;
        height: 530px;
    }
    @media (max-width: 380px) {
        height: 550px;
    }
`;

const SavedSection = styled.div`
    background-color: ${({ savedBackgroundColor }) => savedBackgroundColor};
    width: 100%;
    margin: 0 auto;
    border-radius: 18px;
    padding: 15px;
    margin: 20px auto;
    
    @media (max-width: 600px) {
        padding: 10px;
        margin-top: 10px;
    }
`;

const YouSave = styled.p`
    color: white;
    margin: 0 auto;
    text-align: center;
    font-size: 20px;
    font-weight: 700;

    @media (max-width: 600px) {
        font-size: 17px;
    }
`;

const SavedAmount = styled.p`
    color: white;
    font-size: 50px;
    text-align: center;
    font-weight: 700;

    @media (max-width: 1000px) {
        font-size: 38px;
    }

    @media (max-width: 600px) {
        font-size: 35px;
    }
`;

const OfferDetailContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 600px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .full-width-mobile {
            width: 100%;
        }
    }
`;

const TextColumn = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid white;
    margin: 5px 0 15px 0;

    @media (max-width: 600px) {
        width: 50%;
        text-align: center;

        
    }
`;

const ProposedText = styled.h3`
    font-size: 15px;
    text-transform: uppercase;
    color: #363636;
    color: white;
    font-weight: 900;
    margin: 0;

`;
const ProposedPayment = styled.p`
    color: white;
    font-size: 25px;
    font-weight: 500;
    position: relative;

    .icon {
        font-size: 30px;
        position: absolute;
        top: 20px;
        left: -30px;
        color: white;
    }
`;

const ProposedInformation = styled.p`
    font-size: 25px;
    font-weight: 500;
    color: white;
`;

const CtaWrapper = styled.div`
    margin: 30px auto 20px auto;
    width: 100%;
`;

const NewBundleSection = (props) => {
    const data = props.data;
    
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
      
    return (
        <Container>
        <Title>Let us help you make debt a thing of the past!</Title>
        <SubTitle>Pick an offer and let’s get started.</SubTitle>
        <Wrapper>
            {data.map((offer, index) => {
                let backgroundColor;
                let savedBackgroundColor;
                if (index === 0 ) {
                    backgroundColor = "#10526E";
                    savedBackgroundColor = "#063144";
                } else if (index === 1) {
                    backgroundColor = "#5B97AA"
                    savedBackgroundColor = "#297196";
                } else {
                    backgroundColor = "#27A04B"
                    savedBackgroundColor = "#19723E";
                }
                return (
                <OfferCard key={offer.offerCode} backgroundColor={backgroundColor}>
                    <Offer>OFFER {index + 1}</Offer>
                   {/*  <OfferNumber>{index + 1}</OfferNumber> */}

                   <SavedSection savedBackgroundColor={savedBackgroundColor}>
                        <YouSave>You Save</YouSave>
                        <SavedAmount>${offer.saving}</SavedAmount>
                    </SavedSection>

                    <OfferDetailContainer>
                        <TextColumn>
                            <ProposedText>Pay:</ProposedText>
                            <ProposedPayment>
                            ${(offer.installmentAmount)}
                            </ProposedPayment>
                        </TextColumn>

                        <TextColumn>
                            <ProposedText>Duration:</ProposedText>
                            <ProposedInformation>{offer.noOfPayments} Months</ProposedInformation>
                        </TextColumn>

                        <TextColumn className="full-width-mobile">
                            <ProposedText>Total Payment Including Tax:</ProposedText>
                            <ProposedInformation>${parseFloat((offer.remainingAmount-offer.saving).toFixed(2))}</ProposedInformation>
                        </TextColumn>
                    </OfferDetailContainer>

                    <Cta onClick={() => props.onSelectOffer(offer)} style={{marginTop: '15px'}}>Select Offer {index + 1}</Cta>
                    
                </OfferCard>
                )
            })}
            
        </Wrapper>
        <BottomText>If you would prefer to speak a representative, you can reach Greewave by phone at <a href="tel:3852471968">(385) 247-1968</a> or email at <a href="mailto:support@greenwavefinance.com">support@greenwavefinance.com</a>.</BottomText>

        </Container>
    )
};

export default NewBundleSection;









/* 
<OfferCard>
<Option>Option 1:</Option>
<TextColumn>
    <ProposedPayment>
        <FontAwesomeIcon icon={faDollarSign} alt="Dollar Sign" className="icon" />
        46.21
    </ProposedPayment>
    <ProposedText>Proposed Payment</ProposedText>
</TextColumn>

<Line />

<TextRow>
    <TextColumn>
        <ProposedInformation>Monthly</ProposedInformation>
        <ProposedText>Proposed Frequency</ProposedText>
    </TextColumn>
    <TextColumn>
        <ProposedInformation>6</ProposedInformation>
        <ProposedText>Proposed Length of Plan</ProposedText>
    </TextColumn>
</TextRow>

<Cta onClick={() => props.onSelectOffer('testing parent function')}>Select This Option</Cta>
</OfferCard>

<OfferCard>
<Option>Option 2:</Option>
<TextColumn>
    <ProposedPayment>
        <FontAwesomeIcon icon={faDollarSign} alt="Dollar Sign" className="icon" />
        27.72
    </ProposedPayment>
    <ProposedText>Proposed Payment</ProposedText>
</TextColumn>

<Line />

<TextRow>
    <TextColumn>
        <ProposedInformation>Monthly</ProposedInformation>
        <ProposedText>Proposed Frequency</ProposedText>
    </TextColumn>
    <TextColumn>
        <ProposedInformation>12</ProposedInformation>
        <ProposedText>Proposed Length of Plan</ProposedText>
    </TextColumn>
</TextRow>

<Cta onClick={() => props.onSelectOffer('testing parent function')}>Select This Option</Cta>
</OfferCard> */