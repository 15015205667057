import React from 'react'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';

const LoadingContainer = styled.div`
    background-color: rgba(46, 49, 49, .25);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 6;
    top: 0;
    left: 0;
    position: fixed;
`;

const ErrorModulePopup = styled.div`
    background-color: white;
    border-radius: 5px;
    z-index: 7;
    max-width: 500px;
    height: 170px;
    margin: 20% auto 0 auto;
    position: relative;

    .cancel-icon {
        position: absolute;
        color: #00546C;
        top: 10px;
        right: 15px;
        font-size: 22px;
        cursor: pointer;
    }
    @media (max-width: 500px) {
        height: 200px;
        width: 95%;
    }
    @media (max-width: 400px) {
        height: 250px;
        width: 95%;
    }
`;

const XIcon = styled.div`
    background-color: #00546C;
    box-shadow: 0px 10px 20px #00000029;
    width: 20%;
    height: 100%;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;

    .exclamation-icon {
        color: white;
        font-size: 50px;
        margin: 60px 0 0 24px;
    }

    @media (max-width: 500px) {
        .exclamation-icon {
            margin: 60px 0 0 14px;
        }
    }

   
`;

const Error = styled.h5`
    color: #00546C;
    position: absolute;
    top: 0;
    font-size: 26px;
    margin-top: 15px;
    left: 120px;

    @media (max-width: 500px) {
        left: 100px;
    }
`;

const ErrorMessage = styled.p`
    color: #00546C;
    top: 50px;
    position: absolute;
    left: 120px;
    width: 75%;
    transition: .4s;

    a {
        text-decoration: none;
        color: #00546C;
        font-weight: 600;

        :hover {
            text-decoration: underline;
            transition: .4s;
        }
    }


    @media (max-width: 500px) {
        left: 100px;
        max-width: 68%;
    }

    
`;


const ErrorModule = ( props ) => {    
    return (
        <LoadingContainer onClick={props.exitErrorModule} >
            <ErrorModulePopup>
                <XIcon>
                    <FontAwesomeIcon icon={faExclamationCircle} alt="Dollar Sign" className="exclamation-icon" />
                </XIcon>
                <Error>Oh no!</Error>
                <ErrorMessage>Something went wrong. Please try refreshing your browser. If the problem persists please contact support at <a href="tel:3852471968">(385) 247-1968</a> or email at <a href="mailto:support@greenwavefinance.com">support@greenwavefinance.com</a>.</ErrorMessage>
                <FontAwesomeIcon icon={faTimes} alt="Dollar Sign" className="cancel-icon" onClick={props.exitErrorModule} />
            </ErrorModulePopup>
            
        </LoadingContainer>           
    )
}

export default ErrorModule;
