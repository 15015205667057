export default function creditCardType(cc) {
    let amex = new RegExp('^3[47][0-9]{13}$');
    let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
  
    let mastercard = new RegExp('^5[1-5][0-9]{14}$');
    let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');
  
    let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
    let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
    let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');  
  
    if (visa.test(cc)) {
        console.log('visa')
      return 'visa';
    }
    if (amex.test(cc)) {
        console.log('amex')
      return 'amex';
    }
    if (mastercard.test(cc) || mastercard2.test(cc)) {
        console.log('mastercard')
      return 'mastercard';
    }
    if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
        console.log('discover')
      return 'discover';
    }
    return undefined;
  }
  