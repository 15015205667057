import React from 'react';
import styled from 'styled-components';
import { StaticImage } from "gatsby-plugin-image"
import formatCurrency from '../../../../helpers/formatCurrency';

const Wrapper = styled.div`
    width: 100%;
    background: #DCE8F2;
    margin-top: -80px;
    margin-bottom: 50px;
    padding-bottom: 80px;
    background: #E4ECF5;

    @media (max-width: 1250px) {
        padding: 0 30px 80px 30px;
    }
    @media (max-width: 600px) {
        padding: 0 15px 80px 15px;
    }
`;

const FooterSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 55px 0;


    @media (max-width: 600px) {
        flex-direction: column;
        padding: 15px 0 35px 0;
    }
`;

const TextDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;

    @media (max-width: 600px) {
        width: 100%;
    }
`;

const Header = styled.h1`
    color: #00536D;
    margin-bottom: 15px;
    font-size: 40px;
`;

const Paragraph = styled.p`
    color: #00536D;
    font-size: 17px;
`;

const LogoWrapper = styled.a`
    cursor: pointer;
    width: 234px;

    @media (max-width: 769px) {
        width: 180px;
    }
    @media (max-width: 500px) {
        margin-bottom: 30px;
    }
`;


const DebtCard = styled.div`
    border-radius: 12px;
    padding: 35px 35px 25px 35px;
    max-width: 1200px;
    height: 410px;
    background-color: #F7F7F7;
    margin: 0 auto;

    @media (max-width: 600px) {
        padding: 30px 20px;
        height: 450px;
    }
    @media (max-width: 500px) {
        height: 490px;
    }
`;

const DebtCardHeader = styled.h3`
    color: #00536D;
    font-size: 22px;
    margin: 0 0 15px 20px;
`;

const TitleLogoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const DebtCardContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;

    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 12px;
    height: 200px;
    width: 330px;
    position: relative;
    box-shadow: 0px 10px 20px #00000029;
    padding: 0 15px;

    @media (max-width: 1150px) {
        width: 31%;
        height: 180px;
    }

    @media (max-width: 800px) {
        width: 31%;
        height: 160px;
    }

    @media (max-width: 600px) {
        width: 100%;
        height: 70px;
        margin-bottom: 15px;
    }

`;

const DebtText = styled.h3`
    font-size: 18px;
    color: white;
    font-weight: 600;
    background-color: #002939;
    width: 100%;
    position: absolute;
    top: -15px;
    padding-top: 13px;
    text-align: center;
    height: 50px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;

    @media (max-width: 800px) {
        font-size: 17px;
    }

    @media (max-width: 600px) {
        width: 50%;
        left: 0;
        border-radius: 12px;
        height: 70px;
        padding-top: 22px;
        margin: 0;
        top: 0;
    }
`;

const PastDueAmount = styled.p`
    color: #002939;
    font-size: 50px;
    font-weight: 700;
    position: relative;
    margin-top: 80px;

    .icon {
        font-size: 45px;
        position: absolute;
        top: 13px;
        left: -35px;
        color: #002939;
    }

    @media (max-width: 1150px) {
        font-size: 45px;
    }

    @media (max-width: 800px) {
        font-size: 35px;
        margin-top: 70px;
    }

    @media (max-width: 600px) {
        right: 15px;
        margin-top: 0;
        position: absolute;
        top: 10px;
    }
    @media (max-width: 600px) {
        font-size: 28px;
        right: 15px;
        margin-top: 0;
        position: absolute;
        top: 15px;
    }
`;

const CollexinText = styled.p`
    color: #659CAE;
    text-align: center;
    margin-top: 35px;
    font-weight: 900;

    @media (max-width: 600px) {
        font-size: 15px;
        margin-top: 20px;
    }
`;

const Merchant = styled.p`
    color: #042939;
    text-align: center;
    margin-top: 80px;
    font-size: 20px;
    font-weight: 600;

    @media (max-width: 800px) {
        margin-top: 65px;
        font-size: 18px;
    }
    @media (max-width: 600px) {
        right: 15px;
        margin-top: 0;
        position: absolute;
        top: 10px;
    }
    @media (max-width: 600px) {
        right: 15px;
        margin-top: 0;
        position: absolute;
        top: 22px;
    }
    @media (max-width: 500px) {
        width: 47%;
        text-align: right;
        top: 20px;
        font-size: 16px;
    }
`;

const Description = styled.p`
    color: #042939;
    text-align: center;
    margin-top: 80px;
    font-size: 20px;
    font-weight: 600;
    overflow: hidden;
    height: 45%;

    @media (max-width: 1150px) {
        height: 48%;
    }
    @media (max-width: 800px) {
        margin-top: 65px;
        font-size: 18px;
    }
    @media (max-width: 600px) {
        right: 15px;
        margin-top: 0;
        position: absolute;
        top: 10px;
        width: 40%;
        text-align: right;
    }
    @media (max-width: 500px) {
        width: 45%;
        font-size: 16px;
        height: 75%;
    }
`;

const BannerSection = ({ data }) => {
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <Wrapper>

            <FooterSection>
                <TextDiv>
                    <Header>Hello {capitalizeFirstLetter(data.firstName)} {capitalizeFirstLetter(data.lastName)}</Header>
                    <Paragraph>{data.emailBody}</Paragraph>
                </TextDiv>
            </FooterSection>
        
            
            <DebtCard>

            <TitleLogoContainer>
                <DebtCardHeader>Account Details:</DebtCardHeader>
                <LogoWrapper href="/">
                    <StaticImage src="../../../../resources/images/logos/Greenwave-Finance-Logo.png" alt="Greenwave Logo"  placeholder="none" />
                </LogoWrapper>
            </TitleLogoContainer>
            

                <DebtCardContainer>
                    <TextContainer>
                        <DebtText>Merchant:</DebtText>
                        <Merchant>
                            {data.merchant}
                        </Merchant>
                    </TextContainer>

                    <TextContainer>
                        <DebtText>Past Due Amount:</DebtText>
                        <PastDueAmount>
                            ${data.remainingAmount}
                        </PastDueAmount>
                    </TextContainer>
                </DebtCardContainer>
                <CollexinText>LET’S <span style={{color: '#259551'}}>INCREASE COLLECTIONS</span> AND <span style={{color: '#25677E'}}>DECREASE FRUSTRATIONS </span>FOR ALL.</CollexinText>
            </DebtCard> 
        </Wrapper>
    );
};


export default BannerSection;
