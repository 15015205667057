import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import './Loading.scss';
import { StateData } from './states'; 
import Select from 'react-select';
import { StaticImage } from "gatsby-plugin-image"
import ErrorModule from '../../../molecules/ErrorModule/index';
import checkCardType from '../../../../helpers/getCardType';


const LoadingContainer = styled.div`
    background-color: rgba(46, 49, 49, .25);
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 6;
    top: 0;
    left: 0;
`;

const PaymentWrapper = styled.div`
    border-radius: 12px;
    padding: 30px 45px 55px 45px;
    height: 910px;
    box-shadow: 0px 10px 20px #00000029;
    background-color: #F7F7F7;
    width: 1200px;
    margin: 0 auto 200px auto;
    display: flex;
    justify-content: space-between;
    z-index: 10;

    @media (max-width: 1300px) {
        width: 95%;
        height: 950px;
        margin: 0 auto 150px auto;
    }

    @media (max-width: 769px) {
        flex-direction: column;
        height: 1550px;
        position: relative;
        justify-content: flex-start;
    }
    @media (max-width: 600px) {
        justify-content: flex-start;
        padding: 30px 20px;
        height: 1650px;
    }
    @media (max-width: 400px) {
        height: 1900px;
    }
`;

const TextRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    flex-wrap: wrap;
    width: 100%;

    .full-width-block {
        width: 92%;
    }

    @media (max-width: 769px) {
        .full-width-block {
            width: 97%;
        }
    }
`;


const ProposedText = styled.h3`
    font-size: 15px;
    text-transform: uppercase;
    color: #022737;
    font-weight: 900;
    margin: 0 auto;
`;

const ProposedInformation = styled.p`
    font-size: 25px;
    font-weight: 500;
    color: #022738;
`;

const Header = styled.h1`
    text-transform: uppercase;
    font-size: 22px;
    color: #022738;
    .check-icon {
        color: #5A97AB;
        margin-right: 8px;
        font-size: 26px;
    }

    @media (max-width: 850px) {
        font-size: 20px;
        margin-top: 10px;
    }
    @media (max-width: 480px) {
        font-size: 17px;

        .check-icon {
            font-size: 20px;
        }
    }
`;

const SubHeader = styled.h2`
    font-size: 20px;
    color: #022738;
`;

const PlanConfirmation = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    position: relative;

    @media (max-width: 769px) {
        width: 100%;
    }
    @media (max-width: 769px) {
        position: inherit;
    }
`;

const PaymentInformation = styled.div`
    width: 50%;
    border-left: 1px solid #dedede;
    position: relative;

    .padding {
        padding-left: 40px;
    }
    @media (max-width: 769px) {
        width: 100%;
        border-left: none;
        margin-top: 40px;

        .padding {
            padding-left: 0;
        }
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 6px;
    padding: 40px;
    padding-top: 35px;
    margin: 0 auto;

    .css-1s2u09g-control {
        border-radius: 6px;
        border: 1px solid #dedede;
        height: 45px;
    }
    .css-1pahdxg-control {
        height: 45px;
    }
    .css-b62m3t-container {
        margin: 7px 0;
    }

    .select {
        margin: 7px 0;
        .css-14el2xx-placeholder {
            color: #777777;
            font-size: 15px;
            font-weight: 400;
        }
        .css-1s2u09g-control {
            border-radius: 6px;
            border: 1px solid #dedede;
            height: 45px;
           
        }
        .css-1pahdxg-control {
            height: 45px;
        }
    }

    .error {
        ::placeholder {
            color: red;
        }
        .css-14el2xx-placeholder {
            color: red;
        }
    }

    @media (max-width: 1000px) {
        padding: 30px 30px 36px 30px;
    }

    @media (max-width: 850px) {
        padding-top: 0;
        padding-right: 0;
    }

    @media (max-width: 769px) {
        padding: 0 0 36px 0;
    }
`;

const Input = styled.input`
    background-color: white;
    border: 1px solid #dedede;
    border-radius: 6px;
    width: 100%;
    height: 45px;
    padding-left: 15px;
    color: #777777;
    margin: 7px 0;
    font-size: 15px;

    :focus {
        outline: none;
        border: 2px solid #0F516F;
        color: #777777;
        font-size: 15px;
    }

    ::placeholder {
        font-size: 15px;
    }
`;

const Submit = styled.button`
    width: 100%;
    height: 46px;
    background-color: #27A14B;
    color: white;
    text-align: center;
    border-radius: 12px;
    text-transform: uppercase;
    border: none;
    margin-top: 24px;
    font-weight: 900;
    font-size: 15px;
    cursor: pointer;
    transition: .4s;

    &:hover {
        background: #5A97AB;
        transition: .4s;
    }
`;


const BackButton = styled.p`
    background-color: transparent;
    text-transform: uppercase;
    color: #012838; 
    cursor: pointer;
    padding:  12px 20px 12px 30px;
    height: 46px;
    border-radius: 12px;
    width: 150px;
    font-size: 15px;
    font-weight: 900;
    position: absolute;
    bottom: -5px;
    transition: .4s;
    background-color: #E4ECF5;
    border: 1px solid #E4ECF5;

    .back-arrow {
        color: #012838;
        margin-right: 15px;
        font-size: 17px;
    }

    :hover {
        background-color: white;
        color: #012838;
        border: 1px solid #012838;
        transition: .4s;

        .back-arrow {
            color: #012838;
        }
    }

    @media (max-width: 769px) {
        bottom: -710px;
    }
`;

const ExpirationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: -7px;

    .select {
        width: 33%;
    }

    #code {
        width: 30%;
    }
`;

const PaymentContainer = styled.div`
    display: flex;
`;

const TextContainer = styled.div`
    text-align: center;
    width: 45%;
    background: white;
    padding: 20px;
    border-radius: 15px;
    margin: 5px;

    @media (max-width: 769px) {
        width: 48%;
    }
    @media (max-width: 600px) {
        width: 47%;
    }
    @media (max-width: 400px) {
        width: 100%;
    }
`;

const LogoContainer = styled.div`
    width: 250px;
    position: absolute;
    right: 40px;

    @media (max-width: 600px) {
        width: 200px;
    }
`;

const PaymentSection = (props) => {
    const test = checkCardType('4111111111111111');
    console.log(test)
    console.log(props)

    const tax = 0;
    const total = tax + props.selectedOffer.installmentAmount
    let offer = props.selectedOffer;

    const Months = [
        { value: '01', label: 'January' },
        { value: '02', label: 'February' },
        { value: '03', label: 'March' },
        { value: '04', label: 'April' },
        { value: '05', label: 'May' },
        { value: '06', label: 'June' },
        { value: '07', label: 'July' },
        { value: '08', label: 'August' },
        { value: '09', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' },
    ]

    const Years = [
        { value: '2022', label: '2022' },
        { value: '2023', label: '2023' },
        { value: '2024', label: '2024' },
        { value: '2025', label: '2025' },
        { value: '2026', label: '2026' },
        { value: '2027', label: '2027' },
        { value: '2028', label: '2028' },
    ]   

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    // offer data
    const [offerData, setOfferData] = useState({
        "payment": offer.installmentAmount,
        "frequency":capitalizeFirstLetter(offer.frequency),
        "months": offer.noOfPayments,
        "total_amount": offer.remainingAmount,
        "total_tax": 0,
        "amount_post_tax": offer.installmentAmount,
    })

    //form data
    const [firstNamePlaceholder, setFirstNamePlaceholder] = useState('First Name');
    const [lastNamePlaceholder, setLastNamePlaceholder] = useState('Last Name');
    const [addressPlaceholder, setAddressPlaceholder] = useState('Billing Adress');
    const [cityPlaceholder, setCityPlaceholder] = useState('City');
    const [statePlaceholder, setStatePlaceholder] = useState('State');
    const [zipCodePlaceholder, setZipCodePlaceholder] = useState('Zip Code');

    const [cardNumberPlaceholder, setCardNumberPlaceholder] = useState('Card Number');
    const [monthPlaceholder, setMonthPlaceholder] = useState('Month');
    const [yearPlaceholder, setYearPlaceholder] = useState('Year');
    const [codePlaceholder, setCodePlaceholder] = useState('CVC')

    const [firstName, setfirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');

    const [cardNumber, setCardNumber] = useState('');
    const [expirationMonth, setExpirationMonth] = useState('');
    const [expirationYear, setExpirationYear] = useState('');
    const [code, setCode] = useState('');
    const [errorInput, setErrorInput] = useState(false)

    function validateFields(event) {
        event.preventDefault();
        let errorMessage = " required";
        let shortError = "Required"
        if (firstName === '') {
            setErrorInput(true)
            setFirstNamePlaceholder("First name is" + errorMessage)
        } if (lastName === '') {          
            setLastNamePlaceholder("Last name is" + errorMessage)
        } if (address === '') {          
            setAddressPlaceholder("Address is" + errorMessage)
        } if (city === '') {          
            setCityPlaceholder("City is" + errorMessage)
        } if (state === '') {          
            setStatePlaceholder("State is" + errorMessage)
        } if (zipCode === '') {          
            setZipCodePlaceholder("Postal code is" + errorMessage)
        } if (cardNumber === '') {
            setCardNumberPlaceholder("Card number is" + errorMessage)
        } if (code === '') {
            setCodePlaceholder(shortError)
        } if (expirationMonth === '') {          
            setMonthPlaceholder(shortError)
        } if (expirationYear === '') {          
            setYearPlaceholder(shortError)
        }

        if (firstName !== '' && lastName !== '' && address !== '' && city !== '' && state !== '' && zipCode !== ''  && code !== '' && cardNumber !== '' && expirationMonth !== '' && expirationYear !== '') {
            setLoading(true)
            handleSubmit();
        }
    }

    const selectState = (event) => {
        setState(event.value);
        setStatePlaceholder(event.label)
    }

    const selectMonth = (event) => {
        setExpirationMonth(event.value);
        setMonthPlaceholder(event.label)
    }

    const selectYear = (event) => {
        setExpirationYear(event.value);
        setYearPlaceholder(event.label)
    }

    const exitErrorModuleHandler = () => {
        setError(false)
    }

    const handleSubmit = () => { 
        let formData = {
            "client_id":null,
            "card":{
               "number": cardNumber, // cardNumber, // succeed: 4111111111111111  fail: 4112000022223333
               "cvv": code,
               "expiry_year":expirationYear,
               "expiry_month":expirationMonth,
               "billing":{
                  "firstname": firstName,
                  "lastname": lastName,
                  "middlename": null,
                  "street": address,
                  "street2":null,
                  "city": city,
                  "state": state,
                  "country":"US",
                  "postcode": zipCode,
                  "phone":null,
                  "email":null
               },
               "network": checkCardType(cardNumber),
               "is_debit":false
            },
            "offer":{
               "offer_id": offer.offer_id,
               "debt_id": offer.debt_id,
               "frequency":"monthly",
               "installments": offer.noOfPayments,
               "installment_amount": offer.installmentAmount,
               "total_amount": offer.installmentAmount,
               "installment_amount_post_tax": offer.installmentAmount,
               "total_amount_post_tax": offer.installmentAmount*offer.noOfPayments,
               "issue_date": offer.issue_date,
               "expire_date": offer.expirationDate,
               "total_tax": 0,
               "greenwave_savings": offer.saving
            }
         }
         sessionStorage.setItem('offerData', JSON.stringify(offerData));
         setLoading(true)
         setTimeout(() => {
            setLoading(false)
            window.location = window.location.origin + '/confirm';
         }, 1000);
        // const RequestOptions = {
        //     method: 'POST',
        //     headers: { 
        //         'Content-Type': 'application/json',
        //         'Access-Control-Request-Headers': 'Content-Type'
        //     },
        //     body: JSON.stringify(formData)
        // };

        // fetch('http://localhost:3000/noauth/cardpayment', RequestOptions)
        // .then((response) => response.json())
        // .then((data) => {
        //     if (data.success === false) {
        //         setLoading(false)
        //         setError(true)
        //     } else {
        //         sessionStorage.setItem('offerData', JSON.stringify(offerData));
        //         setLoading(false)
        //         window.location = window.location.origin + '/confirm';
        //     }
        //     console.log(data)
        // })
        // .catch((error) => {
        //     console.error('Error:', error);
        //     setLoading(false)
        //     setError(true)
        // });
    }

    return (
        <PaymentWrapper>
            {loading &&
                <LoadingContainer>
                    <div class="loading-container-demo">
                        <div class="loading-demo"></div>
                    </div>
                </LoadingContainer>
            }
            {error && 
             <ErrorModule exitErrorModule={exitErrorModuleHandler}/> 
            }
            <PlanConfirmation>
                <Header>
                    <FontAwesomeIcon icon={faCheckCircle} alt="Check Sign" className="check-icon" />
                    Confirmation
                </Header>

                <SubHeader>Payment Details:</SubHeader>

                <PaymentContainer>
                    <TextRow>
                        <TextContainer>
                            <ProposedText>
                                Pay:
                            </ProposedText>
                            <ProposedInformation>${offer.installmentAmount}</ProposedInformation>
                        </TextContainer>
                        
                        <TextContainer>
                            <ProposedText>
                                Tax:
                            </ProposedText>
                            <ProposedInformation>${tax}</ProposedInformation>
                        </TextContainer>  

                        <TextContainer className="full-width-block">
                            <ProposedText>
                                Payment Amount Including Tax:
                            </ProposedText>
                            <ProposedInformation>${total}</ProposedInformation>
                        </TextContainer>                           
                    </TextRow>
                </PaymentContainer>

                <SubHeader>Offer Details:</SubHeader>
                <PaymentContainer>
                    <TextRow>
                        <TextContainer>
                            <ProposedText>
                                Payment Amount Including Tax:
                            </ProposedText>
                            <ProposedInformation>${offer.installmentAmount}</ProposedInformation>
                        </TextContainer>
                        
                        <TextContainer>
                            <ProposedText>
                                Duration:
                            </ProposedText>
                            <ProposedInformation>{offer?.noOfPayments} Months</ProposedInformation>
                        </TextContainer>

                        <TextContainer>
                            <ProposedText>
                                Total Amount (pretax): 
                            </ProposedText>
                            <ProposedInformation>${parseFloat((offer.remainingAmount-offer.saving).toFixed(2))}</ProposedInformation>
                        </TextContainer>

                        <TextContainer>
                            <ProposedText>
                                Total Tax: 
                            </ProposedText>
                            <ProposedInformation>${0}</ProposedInformation>
                        </TextContainer>

                        <TextContainer className="full-width-block">
                            <ProposedText>
                                Total Amount Including Tax:
                            </ProposedText>
                            <ProposedInformation>${parseFloat((offer.remainingAmount-offer.saving).toFixed(2))}</ProposedInformation>
                        </TextContainer>
                        
                    </TextRow>
                </PaymentContainer> 

                    <BackButton onClick={props.goBackToOffers}>
                        <FontAwesomeIcon icon={faArrowLeft} alt="Dollar Sign" className="back-arrow" />
                        Back
                    </BackButton>
            </PlanConfirmation>

            <PaymentInformation>
                <Header className="padding">                    
                    <FontAwesomeIcon icon={faCreditCard} alt="Check Sign" className="check-icon" />
                    Payment Information
                </Header>
                <Form>
                
                    <Input
                        type="text"
                        id="firstName"
                        value={firstName}
                        placeholder={firstNamePlaceholder}
                        onChange={event => setfirstName(event.target.value)}
                        className={errorInput ? 'error' : null}
                    />

                    <Input
                        type="text"
                        id="lastName"
                        value={lastName}
                        placeholder={lastNamePlaceholder}
                        onChange={event => setLastName(event.target.value)}
                        className={errorInput ? 'error' : null}
                    />

                    <Input
                        type="text"
                        id="address"
                        value={address}
                        placeholder={addressPlaceholder}
                        onChange={event => setAddress(event.target.value)}
                        className={errorInput ? 'error' : null}
                    />

                    <Input
                        type="text"
                        id="city"
                        value={city}
                        placeholder={cityPlaceholder}
                        onChange={event => setCity(event.target.value)}
                        className={errorInput ? 'error' : null}
                    />

                    <Select 
                        options={StateData} 
                        type="select" 
                        id="states"
                        value={state}
                        placeholder={statePlaceholder}
                        onChange={event => selectState(event)}
                        className={errorInput ? 'error' : null}
                    />

                    <Input
                        type="number"
                        id="zipCode"
                        value={zipCode}
                        placeholder={zipCodePlaceholder}
                        onChange={event => setZipCode(event.target.value)}
                        className={errorInput ? 'error' : null}
                    />

                    <Input
                        type="number"
                        id="cardNumber"
                        value={cardNumber}
                        placeholder={cardNumberPlaceholder}
                        onChange={event => setCardNumber(event.target.value)}
                        className={errorInput ? 'error' : null}
                    />

                    <ExpirationContainer>
                        <Select 
                            options={Months} 
                            type="select" 
                            id="expirationMonth"
                            value={expirationMonth}
                            placeholder={monthPlaceholder}
                            onChange={event => selectMonth(event)}
                            className={`select ${errorInput ? "error" : null}`}
                        />

                        <Select 
                            options={Years} 
                            type="select" 
                            id="expirationYear"
                            value={expirationYear}
                            placeholder={yearPlaceholder}
                            onChange={event => selectYear(event)}
                            className={`select ${errorInput ? "error" : null}`}

                        /> 

                        <Input
                            type="number"
                            id="code"
                            value={code}
                            placeholder={codePlaceholder}
                            onChange={event => setCode(event.target.value)}
                            className={errorInput ? 'error' : null}
                        />
                    </ExpirationContainer>
                    
                    <Submit onClick={validateFields}>Submit Payment</Submit>
                </Form>

                <LogoContainer>
                    <StaticImage src="../../../../resources/images/logos/Greenwave-Finance-Logo.png" alt="Greenwave Logo"  placeholder="none"   />
                </LogoContainer>
            </PaymentInformation>
        </PaymentWrapper>
    )
}

export default PaymentSection;